<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
        <el-button @click="addMoban" type="primary" style="float:right" :loading="loadingFile">新增模板</el-button>
          <el-form :inline="true" ref="searchForm" :model="searchForm">
           
            <el-form-item label="模板名称" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:300px"
                placeholder="请输入关键词"></el-input>
            </el-form-item>
          
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
          </el-form>
      </div>

    <div class="mainbox">
       <el-table
        :data="tableData"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="50" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="模板名称">
        </el-table-column>
        <el-table-column
          label="状态"
          width="100" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.disabled==1" type="danger">已禁用</el-tag>
            <el-tag v-else type="success">已启用</el-tag>
          </template>  
        </el-table-column>
         <el-table-column
          label="模板类型" header-align="center" align="center">
           <template slot-scope="scope">
           {{scope.row.buildIn==1?"系统模板":"自定义模板"}}
           </template>
        </el-table-column>
          <el-table-column
          prop="xiadanTime"
          label="创建时间" header-align="center" align="center">
        </el-table-column>
        <el-table-column
      fixed="right"
      label="操作" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
        <el-button @click="copyClick(scope.row)" type="text" size="small">复制</el-button>
        <!-- <el-popconfirm title="您确定删除吗？" @confirm="handleDel(scope.row)">
              <el-button style="margin-left:10px" type="text" slot="reference" v-if="scope.row.disabled == 1">删除</el-button>
        </el-popconfirm> -->
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
           <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMobanList, delMoban, copyMoban } from "@/api/moban";
import { MessageBox } from 'element-ui'
let moment = require('moment');
export default {
  name: 'Moban',
  data() {
    return {
      searchForm: {
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.role = window.sessionStorage.getItem("role")
      datas.pageSize = that.pageSize;
      datas.orderByCreated = 'DESC'
      getMobanList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.xiadanTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleClose(){
      this.kuaiDiflag = false;
    },
    copyClick(row){
      var objs = {
        id: row.id
      }
      copyMoban(objs).then(res => {
        if(res.code ==200){
          this.$message({
            type: 'success',
            message: '复制成功'
          })
          this.getTopSearch()
        }else{
          that.$message.error(res.message)
        }
      })
      
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    handleClick(row){
      this.$router.push({
        path: '/sendmoban/detail?id=' + row.id
      })
    },
    handleDel(row){
      var objs = {
        id: row.id
      }
      delMoban(objs).then(res => {
        if(res.code ==200){
          this.$message({
            type: 'warning',
            message: res.message
          })
          this.getTopSearch()
        }
      })
    },
    daochu(){
      console.log('eee')
      var that = this
       MessageBox.alert('您是否确认要导出？', {
            confirmButtonText: '立即导出',
            type: 'warning'
          }).then(() => {
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      this.loadingFile = true
      exportOrder(datas).then((res) => {
        if (res.code === 200) {
          that.loadingFile = false
          let link = document.createElement('a')
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

        } else {
          this.$message({
            type: 'warning',
            message: '无记录可导出'
          })
        }
      })
          })
    },
    addMoban(){
      var that = this
      that.$router.push({
        path: "/sendmoban/detail"
      })
    },
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
</style>